@import url('https://use.typekit.net/tel7xit.css');


@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/RobotoCondensed-Medium.woff2') format('woff2'),
        url('../fonts/RobotoCondensed-Medium.woff') format('woff'),
        url('../fonts/RobotoCondensed-Medium.ttf') format('ttf'),
        url('../fonts/RobotoCondensed-Medium.svg') format('svg'),
        url('../fonts/RobotoCondensed-Medium.eot') format('eot');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/RobotoCondensed-MediumItalic.woff2') format('woff2'),
        url('../fonts/RobotoCondensed-MediumItalic.woff') format('woff'),
        url('../fonts/RobotoCondensed-MediumItalic.ttf') format('ttf'),
        url('../fonts/RobotoCondensed-MediumItalic.svg') format('svg'),
        url('../fonts/RobotoCondensed-MediumItalic.eot') format('eot');
    font-weight: 500;
    font-style: italic;
}




a{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

body{
    background:var(--priColor);
    font-size:16px;
    line-height:1.5em;
    font-family:var(--Roboto);
    color:var(--PriTextColor)
}

p{
    line-height:1.8em;
}


:root{
    --Roboto: roboto, sans-serif; 
    --RobotoCond: 'Roboto Condensed';
    --Bebas: bebas-neue, sans-serif;

    --priColor:#00234F;
    --accColor:#FEB913;
    --whiteColor:#ffffff;
    --lightGreyBg:#F5F5F5;

    --PriTextColor:#001838;
    --skyBlueColor:#1AC8FF;
}

.accColor{
    color:var(--accColor)
}
.skyBlueColor{
    color:var(--skyBlueColor)
}

h1, h2, h3, h4{
    font-family:var(--Bebas);
    line-height:0.9em;
}

h5, h6{
    line-height: 1.4em;
}

h2{
    font-size:130px;    
}
h3{
    font-size:96px;
}
h4{
    font-size:70px;
}

h5{
    font-size:24px
}

h6{
    font-size:15px;
    font-family:var(--RobotoCond);
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight:500
}

img{
    max-width:100%;
}

.fullHeight{
    height:100vh;
}

.mainGap{
    padding-left:8%;
    padding-right:8%
}

.section{
    padding-top:160px;
    padding-bottom:160px;
    position: relative;
}
.btnWrap{
    display:inline-block;
    margin-top:10px
}

.toggleClass .showLess{
    display:none
}
.toggleClass.active .showLess{
    display:block
}
.toggleClass.active .showMore{
    display:none
}

.btn{
    font-size:16px;
    font-family:var(--RobotoCond);
    font-weight:500;
    text-transform: uppercase;
    letter-spacing: 2px;
    display:flex;
    align-items: center;
    line-height:normal;
    border-radius:0;
    padding:25px 50px 25px 50px;
    position: relative;
    box-shadow: none;
    border: none;    
}
.btn .btnIcon,
.btn .btnText{
    position: relative;
    z-index: 99;
}

.btn .btnIcon{
    font-size:18px;
    margin-left:5px;
    display:inherit;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; 
    
}

.btn.btn-primary{
    background:var(--accColor);
    color:var(--PriTextColor)
}

.btn.slide-hover::after{
    content:'';
    position: absolute;
    width:0;
    height:100%;
    background:var(--skyBlueColor);
    left:0;
    top:0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;    
}
.btn.slide-hover:hover::after{
    width:100%   
}

.btn.btn-secondary{
    background:var(--priColor);
    color:var(--accColor)
}

.btn.btn-secondary.slide-hover:hover{    
    color:var(--PriTextColor)
}       

.btn.btn-simple{
    padding:0;
    color:var(--accColor);    
}
.btn.btn-simple:hover .btnIcon{
    margin-left:18px
}

.btn.btn-simple.blue{ 
    color:var(--PriTextColor);    
}


.h5{
    color:var(--accColor);
    font-size:32px;
    font-family:var(--RobotoCond);
    line-height:1.4em;
}





/* ============Site Header========= */
.siteHeader{
    padding-top:25px;
    padding-bottom:25px;
    position: fixed;
    z-index: 999999;
    left:0;
    top:0;
    width:100%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.siteHeader.stickyHeader{
    background:var(--whiteColor);    
}

.siteHeader .siteLogo{
    position: relative;
    z-index: 999999;    
}
.siteHeader .siteLogo img{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.siteHeader .siteLogo img.blue{
    opacity: 0;
}
.siteHeader .siteLogo img.white{
    position: absolute;
    left:0;
    top:0;    
}

.siteHeader.stickyHeader .siteLogo img.blue{
    opacity: 1;
}
.siteHeader.stickyHeader .siteLogo img.white{
    opacity: 0;
}

.siteHeader .siteMenu .navBar > ul{
    margin:0;
    list-style: none;
    padding:0;
    margin:auto    
}
.siteHeader .siteMenu .navBar ul > li.menu-item{
    display:inline;
    font-family:var(--RobotoCond);
    font-size:25px;
    margin-left:20px;
    margin-right:20px;
    font-weight:500;
    text-align: center;
}

.siteHeader .siteMenu .navBar ul > li.menu-item > a{
    color:transparent;
    text-decoration: none;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, var(--whiteColor) 50%, var(--priColor) 50%);
    transition: background-position .3s linear;
    -webkit-background-clip: text,border-box;
    background-clip: text,border-box;   
}

.siteHeader .siteMenu .navBar ul > li.menu-item > a:hover{
    background-position: -100% 0;
    color: transparent;
}

.siteHeader.stickyHeader .siteMenu .navBar ul > li.menu-item > a{
    background-size: 200% 100%;    
}

.siteHeader.stickyHeader .siteMenu.text-light .hamburger-react{
    color:var(--PriTextColor)
}
.siteHeader .siteMenu.text-light .hamburger-react > div > div{
    height:2px!important
}
.mainNavigation{
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index: 999;    
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    transform-origin: right;
    clip-path: circle(0 at 90.7% 65px);
}

.mainMenu.active .mainNavigation{
    clip-path: circle(200% at 90.7% 10%);
}
.mainNavigation .overlay{
    background:var(--priColor);
    position: fixed;
    width:100%;
    height:100%;
    opacity: 0.9;
}
.mainNavigation .navBar{
    position: relative;
    z-index: 99;
    background:var(--skyBlueColor);
    width:100%;
    /* max-width:950px; */
    float:right;
    height:100%;
    display:flex;
    align-items: center;
    padding:70px
}



.siteHeader .siteMenu .mainNavigation .navBar ul > li.menu-item{
    display:block;
    font-family:var(--Bebas);
    font-size:90px;
    line-height:normal;    
}
.siteHeader .siteMenu .mainNavigation .navBar .closeMenu{
    position: absolute;
    color:var(--priColor);
    font-size:35px;
    top:50px;
    right:100px;
    cursor: pointer;
}

.siteHeader .siteMenu .hamburger-react{
    position: relative;
    z-index: 99999;    
}


.siteHeader .text-light.siteMenu .mainMenu.active ~ .hamburger-react{
    color:var(--PriTextColor)    
}

.siteHeader .siteLogo.active img.white{opacity: 0;}
.siteHeader .siteLogo.active img.blue{opacity: 1;}



/* =========== Main Banner============ */
.heroBanner{
    position: relative;
}
.heroBanner.section{
    padding-top:100px;
    padding-bottom:100px;
}
.bgSet{
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width:100%;
    pointer-events: none;  

}
.bgSet video{
    object-fit: cover;
    height: 100vh;
    width: 100%;  
}

.bgSet::before{
    content:'';
    position: absolute;
    width:100%;    
    height:100%;
    background:rgba(0,0,0,0.4);    
}
.bgSet.no-overlay::before{
    display:none;
}

.heroBanner .heroAreaContent{
    position: relative;
    z-index: 999;
    width:100%;
}

.relative{
    position: relative;
    z-index: 99;
}

/* ========= Home Page ========= */
.bgPattern{
    background:url('../images/rightPattern.svg') repeat-y;
    background-position-x: right;
}

.featureWraps .snglWrap{
    line-height:normal;
    font-size:24px;
    display:flex;
    align-items: center;
    margin:25px 0;
    color:var(--PriTextColor);
    font-family:var(--RobotoCond);
    font-weight:500
}
.featureWraps .snglWrap .featIcon{
    margin-right:15px;
    font-size:50px;
    display:flex;
    align-items: center;
    color:var(--accColor);
    position: relative;    
}
.featureWraps .snglWrap .featIcon::after{
    position: absolute;
    width:50%;
    height:50%;
    top:50%;
    left:50%;
    background:var(--PriTextColor)    ;
    content:'';
    transform:translate(-50%, -50%);    
}
.featureWraps .snglWrap .featIcon svg{
    position: relative;
    z-index: 99;
}

.swiperSlider .swiper {
    padding-left:8%;
    padding-right:8%;
}
.swiperSlider .swiper .swiper-slide img{
    width:100%;
    max-height:600px;

}
.swiperSlider .contentWrap h4{
    font-family:var(--RobotoCond);
    font-weight:500;
    font-size:32px;
    text-transform: capitalize;
}
body .swiperSlider .swiper-button-prev,
body .swiperSlider .swiper-rtl .swiper-button-next{
    left:2%;
}
body .swiperSlider .swiper-button-next,
body .swiperSlider .swiper-rtl .swiper-button-prev{
    right:2%;
}


.allQuotes .snglQuote{
    padding-top:80px;
    padding-bottom:80px;
    border-top:1px solid #6B6B6B
}
.allQuotes .snglQuote:last-child{
    border-bottom:1px solid #6B6B6B
}


.newsEvents{
    background:var(--lightGreyBg);
}
.featurePost .postWrap{
    background:var(--whiteColor);
}
.featurePost .postWrap .featImg{
    overflow: hidden;
    max-height:600px
    
}
.featurePost .postWrap .featImg img{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.featurePost .postWrap .featImg:hover img{
    transform:scale(1.1)
}
.featurePost .contentWrap{
    padding:70px;
}

.pendingPosts{
    flex-direction: column;
    justify-content: space-between;
    display:flex;
}

.pendingPosts .snglPost{
    padding:50px;
    background:var(--whiteColor);
    position: relative;
}

.pendingPosts .snglPost h5{
    font-family:var(--RobotoCond);
    font-weight:500;
    margin-top:15px;
    padding-right:70px
}
.pendingPosts .snglPost h5 a{
    text-decoration: none;
    color:var(--PriTextColor);
}
.pendingPosts .snglPost:hover{
    background:var(--skyBlueColor)
}
.pendingPosts .snglPost .btnWrap{
    position: absolute;
    right:50px;
    bottom:50px;
}
.pendingPosts .snglPost .btnWrap a.btn{
    font-size:25px;
    color:var(--PriTextColor)
}



.bigTitle{
    font-size:300px;
    line-height:0.8em
}
.bigTitle a{
    text-decoration: none;
    color:var(--skyBlueColor);    
}
.bigTitle a:hover span{
    color:var(--skyBlueColor)
}
.bigTitle a span.accColor svg{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.bigTitle a:hover span.accColor svg{
    margin-left:35px;
}
.bigTitle a:hover span.accColor svg path{
    fill:var(--skyBlueColor);    
}

/* ==========Footer========== */
.siteFooter{
    background:var(--PriTextColor);           
}
.footerMenu ul{
    list-style: none;
    padding:0
}
.footerMenu ul li{
    display:block;
    font-family:var(--RobotoCond);
    font-size:32px;    
    padding:20px 0    
}
.footerMenu ul li a{
    text-decoration: none;
    color:transparent;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, var(--whiteColor) 50%, var(--skyBlueColor) 50%);
    transition: background-position .3s linear;
    -webkit-background-clip: text,border-box;
    background-clip: text,border-box;
}
.footerMenu ul li a:hover{
    background-position: -100% 0;
}

.socialMediaMenu ul{
    padding: 0;
}

.socialMediaMenu ul li{
    display: inline;
    font-size:20px;
    margin-left:30px
}
.socialMediaMenu ul li a{
    color:var(--whiteColor);
}

.list-group .list-group-item{
    padding:0;
    background:transparent;
    box-shadow:none;
    border:none;
    padding-top:25px;
    padding-bottom:25px;
}
.list-group .list-group-item .badge{
    font-size:24px;
    background:var(--accColor);
    width:45px;
    height:45px;
    display:flex;
    align-items: center;    
    justify-content: center;
    font-weight:500;
    font-family:var(--RobotoCond);
    color:var(--PriTextColor)
}
.list-group .list-group-item .contentWrap h5{
    font-family:var(--RobotoCond);
    font-weight: 500;
    margin-bottom:0;;
}
.list-group .list-group-item .contentWrap p{
    margin-bottom:0;
}

.countUp .countNumber{
    font-size:500px;
    line-height:0.8em;
}

.hibHistory .snglHistory .imgArea{
    padding:80px 100px;
    padding-left:0;
    border-right:1px dashed var(--PriTextColor);
    position: relative;
}
.hibHistory .snglHistory .imgArea .hisYears{
    position: absolute;
    bottom:calc(80px + 30px);
    left:40px;
}
.hibHistory .snglHistory .contArea{
    padding:80px 100px;
}

.hibHistory .snglHistory.flex-row-reverse .imgArea{
    padding-right:0;
    padding-left:100px;
    border-right:0;
    border-left:1px dashed var(--PriTextColor)
}
.hibHistory .snglHistory.flex-row-reverse .imgArea .hisYears{
    left:auto;
    right:40px;
}

.blockArea{
    margin-top:70px;    
}
.blockArea .imgArea{
    min-height:650px;
}
.blockArea .contArea{
    background:var(--accColor);
    padding:120px;
}

.allAccordion .accordion {
    --bs-accordion-bg: none;
}
.allAccordion .snglAccordion .accordion-button::after{
    display:none
}
.allAccordion .snglAccordion.accordion-item{
    border:none;
    background:transparent;
}
.allAccordion .snglAccordion.accordion-item button{
    padding:0;
    border-radius:none;
    text-transform: normal!important;
    font-family:var(--RobotoCond);
    font-weight:500;
    background:none;
    box-shadow: none;
    padding:50px 35px;

}
.allAccordion .snglAccordion.accordion-item .accordion-header button svg{
    width:45px;
    height:45px;
    padding:10px;
    border-radius:100%;
    color:var(--accColor);
    background:var(--priColor);
    margin-right:35px;
}

.allAccordion .snglAccordion.accordion-item .accordion-header button.collapsed svg{
    background:var(--accColor); 
    color:var(--PriTextColor)
}

.allAccordion .snglAccordion.accordion-item:hover .accordion-header button svg{
    background:var(--priColor);
    color:var(--accColor)
}

.allAccordion .snglAccordion.accordion-item{
    border-radius:0
}

.allAccordion .snglAccordion.accordion-item{
    color:var(--PriTextColor)
}


.allAccordion .snglAccordion.accordion-item .accordion-header:hover button.collapsed,
.allAccordion .snglAccordion.accordion-item .accordion-header button{
    background:var(--skyBlueColor);
    border-radius:0;
    color:var(--PriTextColor)
}

.allAccordion .snglAccordion.accordion-item .accordion-header button.collapsed{
    background:none
}


.allAccordion .snglAccordion.accordion-item .accordion-header button .plus{
    display:none
}

.allAccordion .snglAccordion.accordion-item .accordion-header button.collapsed .minus{
    display:none;
}
.allAccordion .snglAccordion.accordion-item .accordion-header button.collapsed .plus{
    display:block;
}
.allAccordion .snglAccordion.accordion-item button:focus{
    border:none;
    box-shadow: none;
}

.allAccordion .snglAccordion.accordion-item .accordion-body{
    padding:50px 100px;
}


.allAccordion .snglAccordion.accordion-item .accordion-header h5{
    font-size:32px;    
}
